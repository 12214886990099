export default {
  heading: 'Сохраненные товары',
  heading2: 'Заказанные товары',
  back: 'Назад',
  next: 'Далее',
  button: 'Оставить заявку на сохраненные товары',
  button2: 'Удаление сохраненных товаров',
  button3: 'Удаление заказанных товаров',
  deletedProducts: ' товар(а/ов), которые вы добавили в корзину, были удалены с сайта.',
  deletedOrderedProductsOne: ' заказанный вами товар был удален с сайта.',
  deletedOrderedProductsMany: ' заказанных вами товар(а/ов) были удалены с сайта.',
  date: 'Дата',
  kassa: 'Касса',
  izoh: 'Объяснение',
  amount: 'Сумма',
  totalAmount: 'Общая сумма',
  count: 'Количество',
  totalCount: 'Общее количество',
  currency: 'Валюта',
  kirim: 'Вход',
  chiqim: 'Выход',
  aylanma: 'Оборот',
  boshqoldiq: 'Первый остаток',
  oxirgiqoldiq: 'Последний остаток',
  product: 'Товар',
  products: 'Товары',
  character: 'Характеристика',
  yourOrder: 'Ваш заказ',
  goToCheckout: 'Перейти к оформлению',
}