export default {
  // Globals
  headerPageTitle: 'Home',
  viewModel: 'View 3D Model',
  addToCart: 'Add to cart',
  removeToCart: 'Remove from cart',
  remove: 'Remove',
  nothingHere: "There's nothing here...",
  // For navbar
  heading: 'Production of plastic packaging',
  contactsSelect: 'Contact us',
  about: 'About company',
  catalog: 'Catalog',
  press: 'Press forms',
  contact: 'Contacts',
  forOrders: 'For application',
  profile: 'My Profile',
  savedProducts: 'Saved products',
  policy: 'Social responsibility policy',
  more: 'More',
  siteMore: 'Sections of the site',
  // Auth
  login: 'Login to account',
  register: 'Register',
  register2: 'Register',
  isRegister: 'Already registered?',
  noRegister: 'Not registered?',
  cancel: 'Cancel',
  password: 'Password',
  error: 'You are not logged in yet!',
  success: 'You have successfully logged into your account!',
  success1: 'Your account has been successfully registered!',
  success2: 'What opportunities does it give you?',
  success3: 'Now you can save the products you like in your personal shopping cart.',
  success4: 'Where can I view the saved products?',
  success5: `At the top of the page, to the left of the language selection field, there is a site navigation bar. A single click on the "Saved Products" entry in this navigation bar will take you to the page for viewing your saved products. If you don't see "Saved Products" you will see "Site Sections" or "More". Clicking on this entry will open an additional navigation bar and you will find it there. If you still don't see "Saved Products" at all, then you're either not signed in yet, or you've been signed out automatically. In this case, you will need to re-enter the account.`,
  success6: 'Why do you need stored products?',
  success7: 'You will be able to contact us about the products you have saved. That is, you can send your saved products directly to us on the "Saved products" page. We will contact you about these products via your phone number or email. To do this, you need to click on the button "Leave a request for saved products" at the bottom of the "Saved products" page.',
  success8: 'Close',
  forgotPass: 'Forgot password?',
  ourDocs: 'Our Documents',
  sum: 'Sum',
}