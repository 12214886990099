export default {
  title: 'Searching results',
  heading: 'Searching results',
  resultsNotFound: 'There are no results for the selected parameters!',
  vendor: 'Vendor code',
  standard: 'Throad standard',
  height: 'Height',
  diameter: 'Diameter',
  volume: 'Volume',
  material: 'Material',
  category: 'Category',
  color: 'Color',
  price: 'Price',
}