import auth from './auth'
import hero from './hero'
import order from './order'
import saved from './saved'
import about from './about'
import navbar from './navbar'
import profile from './profile'
import catalogs from './catalogs'
import contacts from './contacts'
import notFound from './notFound'
import warehouse from './warehouse'
import companyNews from './companyNews'
import premiumModel from './premiumModel'
import searchResults from './searchResults'
import catalogSearch from './catalogSearch'

const TranslationRU = {
  auth,
  hero,
  order,
  saved,
  about,
  navbar,
  profile,
  catalogs,
  contacts,
  notFound,
  warehouse,
  companyNews,
  premiumModel,
  searchResults,
  catalogSearch,
}

export default TranslationRU