import { ISettings } from 'types'
export const localeCatalogsPathLinks: string[] = [
  'bottles-for-cosmetics',
  'jars-for-cosmetics',
  'pachaging-for-decorative-cosmetics',
  'accessories-for-bottles',
  'packaging-for-household-chemicals',
  'packaging-for-medicine',
  'caps-and-shoulders-for-tubes',
]
export const localeSettings: ISettings = {
  id: 'locale-settings',
  gmail: { en: 'Flakonuz@gmail.com', ru: 'Flakonuz@mail.ru' },
  phone: ['+998881561256', '+998991101256'],
  telegram: 'Flakonuz',
  instagram: 'Flakonuz',
  website: 'flakon.uz',
  videoLink: 'https://www.youtube.com/embed/EsBzrWHvHBg?si=TdblWnEee-qvkP6I',
  addressName: {
    en: 'Yakkasaroy district, Shota Rustaveli 1, 1st',
    ru: 'Яккасарайский район, ул. Шота Руставели 1, 1-я',
    uz: 'Yakkasaroy tumani, Shota Rustaveli 1, 1-chi uy',
  },
  privacyPolicy: '',
  exchangeRates: { usd: '', rub: '' },
  catalogPDF: '',
  priceList: '',
  aboutDescription: { en: '', ru: '', uz: '' },
  produceDescription: { en: '', ru: '', uz: '' },
  advantagesDescription: { en: '', ru: '', uz: '' },
  currentCurrency: (localStorage?.getItem('$flakonuz$price$exchange$rate$currency$') as 'RUB' | 'USD' | 'UZS') || 'UZS'
}