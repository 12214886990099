export default {
  heading: 'About company',
  videoHeading: 'To start the video, click on “Play”',
  videoTitle: 'Video about Flakon.uz site',
  aboutCompany: `<strong>«Flakon.uz»</strong> - is a sales department of 30 factories. That factories produce plastic bottles, caps, triggers, pumps, sprays and other plastic products. <b>«Flakon.uz» </b> is a unique Uzbek manufacturing company that offers a full range of services for production and decorating of plastic packaging for perfumery, cosmetics, chemical, auto chemistry and medical industries. For more than 17 years the company has demonstrated continuous growth and development, innovation in technologies and design solutions.
  <b>«Flakon.uz» </b> is one of the well-received trading platforms in Uzbekistan and Central Asia, selling plastic bottles, caps, sprays, triggers, and other plastic products.`,
  weProduce: `
  <li>
    <a href='/catalog/jars-for-cosmetics'>Cosmetic bottles </a> from PE, PP, PET and HDPE with a volume of 15 -10000 ml, with a soft-touch effect; 
  </li>
  <li>
    <a href='/catalog/packaging-for-household-chemicals'>Bottles and jars for household and professional chemistry, </a> auto-care products with a volume from 100 to 3000 ml;
  </li>
  <li>
    <a href='/catalog/packaging-for-medicine'>Packaging for medicine industry </a> with a volume from 4 to 250 ml;
  </li>
  <li>
    <a href='/catalog/caps-and-shoulders-for-tubes'>Flip-top and disk-top caps for bottles </a> - standard neck throat 24/410 DIN, 28/410 DIN, 20/415 DIN;
  </li>
  <li>
    <span>Packaging for the hotel business </span> - PET and HDPE bottles with a volume of 15-30 ml;
  </li>
  <li>
    Optional: <a href='/catalog/accessories-for-bottles'>we complete the bottles </a> with various tops: triggers, sprays, dispensers etc;
  </li>
`,
  weAdvantages: `
  <li>
    <strong>Huge experience </strong> – For more than 17 years (2006) Flakon.uz has been creating beautiful and high-quality packaging for your product;
  </li>
  <li>
    <strong>Powerful tool base </strong> – almost all moulds are made in-house, which is absolutely advantageous due to optimal terms of launching new projects;
  </li>
  <li>
    <strong>The widest range of technologies for processing plastics: </strong> Injection mounding, including bi-colored injection on flip-top caps, blow molding, injection blow molding for PP, and PET;
  </li>
  <li>
    <strong>Flakon.uz </strong> works with the widest range of raw materials such as PP, PE, PET, HDPE;
  </li>
  <li>
    <strong>Flakon.uz </strong> provides quality control at each stage of the production process;
  </li>
  `,
  produceAboutHeading: 'We produce',
  advantagesAboutHeading: 'Our capabilities and advantages',
  ourPartners: 'Our partners',
  seoTitle: 'Plastic Packaging Manufacturer',
  seoDescription: 'Flakon.uz - unique Uzbek company offering complete services for manufacturing and decorating plastic packaging for perfume, auto chemistry and medical sectors.',
}