export default {
  heading: 'Profil',
  settings: 'Profil sozlamalari',
  accounting: 'Xisob kitob',
  ordersFromFlakonuz: "Flakon.uz'dan Buyurtmalar",
  orders: 'Mening Buyurtmalarim',
  order: 'Buyurtma',
  client: 'Mijoz',
  saler: 'Sotuvchi',
  process: 'Jarayon',
  status: 'Status',
  inProcess: 'Bajarilmoqda',
  you: 'Siz',
  colorName: 'Rang nomi',
  count: 'Miqdori',
  ready: 'Tayyor',
  checkingProducts: 'Buyurtmadagi mahsulotlarni ishlab chiqarish jarayoni',
  startThisPartProcess: 'Buyurtmaning ushbu qismini boshlash',
  warehouse: 'Mening Omborim',
  customer: 'Buyurtma beruvchi',
}