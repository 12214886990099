import{lazy}from'react'
const Home=lazy(()=>import('./home/Home'))
const News=lazy(()=>import('./news/News'))
const About=lazy(()=>import('./about/About'))
const Orders=lazy(()=>import('./orders/Orders'))
const NewsView=lazy(()=>import('./news/NewsView'))
const Catalog=lazy(()=>import('./catalog/Catalog'))
const Profile=lazy(()=>import('pages/user/Profile'))
const Contacts=lazy(()=>import('./contacts/Contacts'))
const Catalogs=lazy(()=>import('./catalogs/Catalogs'))
const NotFound=lazy(()=>import('./NotFound/NotFound'))
const Warehouse=lazy(()=>import('./warehouse/Warehouse'))
const ForOrders=lazy(()=>import('./forOrders/ForOrders'))
const ProductView=lazy(()=>import('./productView/ProductView'))
const PremiumModels=lazy(()=>import('./3DModel/PremiumModels'))
const Accounting=lazy(()=>import('pages/accounting/Accounting'))
const SearchResult=lazy(()=>import('./searchResult/SearchResult'))
const OrdersForClients=lazy(()=>import('./orders/OrdersForClients'))
const ProfileSettings=lazy(()=>import('pages/user/ProfileSettings'))
const OrderCheckout=lazy(()=>import('./orderCheckout/OrderCheckout'))
const SavedProducts=lazy(()=>import('./savedProducts/SavedProducts'))
const WarehouseProductAdd=lazy(()=>import('./warehouse/add/WarehouseProductAdd'))
export{Home,News,About,NewsView,Catalog,Contacts,Catalogs,NotFound,ForOrders,PremiumModels,ProductView,SearchResult,SavedProducts,Profile,ProfileSettings,Accounting,OrderCheckout,Orders,OrdersForClients,Warehouse,WarehouseProductAdd}