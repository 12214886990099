export default {
  title: 'Qidiruv natijalari',
  heading: 'Qidiruv natijalari',
  resultsNotFound: 'Tanlangan parametrlar boʻyicha natijalar yoʻq!',
  vendor: 'Sotuvchi kodi',
  standard: 'Tomoq standarti',
  height: 'Balandligi',
  diameter: 'Diametri',
  volume: 'Volumetrik',
  material: 'Material',
  category: 'Turkum',
  color: 'Rang',
  price: 'Narxi',
}