export default {
  error1: 'Iltimos, e-pochtangizni kiriting.',
  error2: "Iltimos, to'g'ri e-pochta manzilini kiriting.",
  error3: 'Iltimos, parolni kiriting.',
  error4: "Parol kamida 6 ta belgidan iborat bo'lishi kerak.",
  error5: "Parol ko'pi bilan 8 ta belgidan iborat bo'lishi kerak.",
  error6: 'Iltimos, ismingizni kiriting.',
  error7: 'Iltimos, familiyangizni kiriting.',
  error8: 'Iltimos, telefon raqamingizni kiriting.',
  error9: 'Iltimos, tasdiqlash kodini kiriting.',
  emailNoRequired: 'Elektron pochtani kiritish majburiy emas!'
}