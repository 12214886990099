export default {
  heading: 'Profile',
  settings: 'Profile settings',
  accounting: 'Accounting book',
  ordersFromFlakonuz: 'Orders from Flakon.uz',
  orders: 'My Orders',
  order: 'Order',
  client: 'Client',
  saler: 'Saler',
  process: 'Process',
  status: 'Status',
  inProcess: 'In process',
  you: 'You',
  colorName: 'Color name',
  count: 'Quantity',
  ready: 'Ready',
  checkingProducts: 'Production process of ordered products',
  startThisPartProcess: 'Start this part of the order',
  warehouse: 'My Warehouse',
  customer: 'Customer',
}