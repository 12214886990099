export default {
  title: 'Каталог',
  heading: 'Упаковка для косметики, бытовой химии и парфюмерии',
  downloadBtn: 'Скачайте каталог',
  downloadBtnPdf: 'Скачать PDF',
  downloadBtnPdf2: 'Скачать Каталог PDF',
  priceListBtnPdf: 'Скачать прайс-лист',
  shareVia: 'Отправить по:',
  copy: 'Копировать ссылку',
  accessories: 'Комплектующи',
}