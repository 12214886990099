// Types
import { IRoute } from 'types'

// Layouts
import MainLayout from 'layouts/MainLayout'

// Auth Pages
import { Login, Register, ForgotPassword } from 'pages/auth'

import {
  News,
  Home,
  About,
  Orders,
  Catalog,
  Profile,
  Catalogs,
  Contacts,
  NotFound,
  NewsView,
  Warehouse,
  ForOrders,
  Accounting,
  ProductView,
  SearchResult,
  SavedProducts,
  PremiumModels,
  OrderCheckout,
  ProfileSettings,
  OrdersForClients,
  WarehouseProductAdd,
} from 'pages'

// Constants for route paths
const ROUTE_HOME = '/'
const ROUTE_NEWS = '/news'
const ROUTE_NOT_FOUND = '*'
const ROUTE_ABOUT = '/about'
const ROUTE_LOGIN = '/login'
const ROUTE_CATALOG = '/catalog'
const ROUTE_PROFILE = '/profile'
const ROUTE_NEWS_ID = '/news/:id'
const ROUTE_CONTACTS = '/contacts'
const ROUTE_REGISTER = '/register'
const ROUTE_ORDERS = '/profile/orders'
const ROUTE_FOR_ORDERS = '/for-orders'
const ROUTE_CATALOG_ID = '/catalog/:id'
const ROUTE_3D_MODEL_ID = '/3DModel/:id'
const ROUTE_ORDER_CHECKOUT = '/ordering'
const ROUTE_WAREHOUSE = '/profile/warehouse'
const ROUTE_SEARCH_RESULT = '/search-result'
const ROUTE_SAVED_PRODUCTS = '/savedProducts'
const ROUTE_ACCOUNTING = '/profile/accounting'
const ROUTE_FORGOT_PASSWORD = '/forgot-password'
const ROUTE_PROFILE_SETTINGS = '/profile/settings'
const ROUTE_WAREHOUSE_ADD = '/profile/warehouse/add'
const ROUTE_CATALOG_ID_PRODUCT_ID = '/catalog/:id/:id'
const ROUTE_ORDERS_FROM_OUR = '/profile/orders-from-our'

// Generate routes based on authentication status
const generateRoutes = (authed: boolean, isAccountingAllowed: boolean, isOrderingAllowed: boolean, role: string): IRoute[] => {
  const routes: IRoute[] = [
    {
      path: ROUTE_HOME,
      element: <MainLayout />,
      children: [
        { path: ROUTE_HOME, element: <Home /> },
        { path: ROUTE_NEWS, element: <News /> },
        { path: ROUTE_ABOUT, element: <About /> },
        { path: ROUTE_NEWS_ID, element: <NewsView /> },
        { path: ROUTE_CATALOG, element: <Catalogs /> },
        { path: ROUTE_CONTACTS, element: <Contacts /> },
        { path: ROUTE_NOT_FOUND, element: <NotFound /> },
        { path: ROUTE_CATALOG_ID, element: <Catalog /> },
        { path: ROUTE_FOR_ORDERS, element: <ForOrders /> },
        { path: ROUTE_3D_MODEL_ID, element: <PremiumModels /> },
        { path: ROUTE_SEARCH_RESULT, element: <SearchResult /> },
        { path: ROUTE_CATALOG_ID_PRODUCT_ID, element: <ProductView /> },
      ],
    },
  ]

  // Add authentication-dependent routes
  if (!authed) {
    routes.unshift({ path: ROUTE_LOGIN, element: <Login /> }, { path: ROUTE_REGISTER, element: <Register /> }, { path: ROUTE_FORGOT_PASSWORD, element: <ForgotPassword /> })
  } else {
    routes?.[0]?.children?.push(
      { path: ROUTE_PROFILE, element: <Profile /> },
      { path: ROUTE_SAVED_PRODUCTS, element: <SavedProducts /> },
      { path: ROUTE_PROFILE_SETTINGS, element: <ProfileSettings /> },
    )

    if (isAccountingAllowed) routes?.[0]?.children?.push({ path: ROUTE_ACCOUNTING, element: <Accounting /> })
    if (isOrderingAllowed) routes?.[0]?.children?.push({ path: ROUTE_ORDER_CHECKOUT, element: <OrderCheckout /> })
    if (role === 'manufacturer') routes?.[0]?.children?.push({ path: ROUTE_ORDERS, element: <Orders /> })
    if (role === 'manufacturer') routes?.[0]?.children?.push({ path: ROUTE_WAREHOUSE, element: <Warehouse /> })
    if (role === 'manufacturer') routes?.[0]?.children?.push({ path: ROUTE_WAREHOUSE_ADD, element: <WarehouseProductAdd /> })
    if (role === 'client' || role === 'manufacturer') routes?.[0]?.children?.push({ path: ROUTE_ORDERS_FROM_OUR, element: <OrdersForClients /> })
  }

  return routes
}

export default generateRoutes