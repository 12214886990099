export default {
  heading: 'Kompaniya haqida',
  videoHeading: 'Videoni boshlash uchun “Ijro” tugmasini bosing.',
  videoTitle: 'Flakon.uz sayti haqida video',
  aboutCompany: `<strong>«Flakon.uz»</strong> - 30 ta zavodning savdo bo'limi. Zavodlar plastik butilkalar, qopqoqlar, triggerlar, nasoslar, spreylar va boshqa plastmassa buyumlar ishlab chiqaradi. <b>«Flakon.uz» </b> - parfyumeriya, kosmetika, kimyo, avtokimyo va tibbiyot sanoati uchun plastik qadoqlarni ishlab chiqarish va bezash bo'yicha xizmatlarning to'liq spektrini taklif qiluvchi noyob o'zbek ishlab chiqarish kompaniyasi. 17 yildan ortiq vaqt mobaynida kompaniya uzluksiz o'sish va rivojlanishni, texnologiyalar va dizayn echimlarida innovatsiyalarni namoyish etdi.
  <b>«Flakon.uz» </b> - O'zbekiston va Markaziy Osiyoda plastik butilkalar, qopqoqlar, spreylar, triggerlar va boshqa plastik mahsulotlarni sotadigan yaxshi qabul qilingan savdo maydonchalaridan biri.`,
  weProduce: `
  <li>
    <a href='/catalog/jars-for-cosmetics'>Косметические флаконы </a> из ПЭ, ПП, ПЭТ и ПЭВП объемом 15-10000 мл, с эффектом soft-touch;
  </li>
  <li>
    <a href='/catalog/packaging-for-household-chemicals'>Бутылки и баночки для бытовой и профессиональной химии, </a> автокосметики объемом от 100 до 3000 мл;
  </li>
  <li>
    <a href='/catalog/packaging-for-medicine'>Упаковка для медицинской промышленности </a> объемом от 4 до 250 мл;
  </li>
  <li>
    <a href='/catalog/caps-and-shoulders-for-tubes'>Крышки с откидной и дисковой крышкой для бутылок </a> - стандартное горлышко 24/410 DIN, 28/410 DIN, 20/415 DIN;
  </li>
  <li>
    <span>Упаковка для гостиничного бизнеса </span> - бутылки ПЭТ и ПНД объемом 15-30 мл;
  </li>
  <li>
    Дополнительно: <a href='/catalog/accessories-for-bottles'>комплектуем флаконы </a> различными крышками: триггерами, спреями, дозаторами и т.д.;
  </li>
`,
  weAdvantages: `
  <li>
    <strong>Огромный опыт </strong> – Более 17 лет (2006 год) Flakon.uz создает красивую и качественную упаковку для вашего продукта;
  </li>
  <li>
    <strong>Мощная инструментальная база </strong> – практически все формы изготавливаются собственными силами, что является огромным преимуществом за счет оптимальных сроков запуска новых проектов;
  </li>
  <li>
    <strong>Самый широкий спектр технологий переработки пластмасс: </strong> литье под давлением, в том числе двухцветное впрыскивание на флип-топ колпачки, выдувное формование, литьевое выдувное формование для ПП и ПЭТ;
  </li>
  <li>
    <strong>Flakon.uz </strong> работает с самым широким спектром сырья, такого как ПП, ПЭ, ПЭТ, ПНД;
  </li>
  <li>
    <strong>Flakon.uz </strong> обеспечивает контроль качества на каждом этапе производственного процесса;
  </li>
`,
  produceAboutHeading: 'Biz ishlab chiqaramiz',
  advantagesAboutHeading: 'Bizning imkoniyatlarimiz va afzalliklarimiz',
  ourPartners: 'Bizning hamkorlarimiz',
  seoTitle: 'Plastik qadoqlash ishlab chiqaruvchi kompaniya',
  seoDescription: "Flakon.uz - parfyumeriya, avtokimyo va tibbiyot sohalari uchun plastik qadoqlarni ishlab chiqarish va bezash bo'yicha to'liq xizmatlarni taklif qiluvchi noyob o'zbek kompaniyasi.",
}