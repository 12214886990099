import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

// Define types for Telegram Web App SDK
interface TelegramWebApp {
  enableClosingConfirmation: () => void
  onEvent: (event: string, callback: () => void) => void
}

interface Window {
  TelegramWebApp?: TelegramWebApp
}

const useTelegramWebAppNavigation = () => {
  const navigate: NavigateFunction = useNavigate()

  useEffect(() => {
    const isFromTelegramBot: boolean = typeof window !== 'undefined' && typeof (window as any)?.TelegramWebviewProxy !== 'undefined'
    if (!isFromTelegramBot) return

    // Check if Telegram Web App SDK is available
    if ((window as Window)?.TelegramWebApp) {
      const { TelegramWebApp } = window as Window

      // Enable closing confirmation if needed
      TelegramWebApp?.enableClosingConfirmation()

      // Event listener for back button press
      const handleBackButtonPress = () => navigate(-1)
      TelegramWebApp?.onEvent('backButtonPressed', handleBackButtonPress)

      // Cleanup function to remove event listeners
      return () => {
        TelegramWebApp?.onEvent('backButtonPressed', handleBackButtonPress)
      }
    }
  }, [navigate])

  // Handle closing confirmation if needed
  useEffect(() => {
    const isFromTelegramBot: boolean = typeof window !== 'undefined' && typeof (window as any)?.TelegramWebviewProxy !== 'undefined'
    if (!isFromTelegramBot) return

    if ((window as Window)?.TelegramWebApp) {
      ;(window as Window)?.TelegramWebApp?.enableClosingConfirmation()
    }
  }, [])
}

export default useTelegramWebAppNavigation
