export default {
  error1: 'Please enter your email.',
  error2: 'Please enter a valid email address.',
  error3: 'Please enter your password.',
  error4: 'Password must be at least 6 characters long.',
  error5: 'Password must be at most 8 characters long.',
  error6: 'Please enter your name.',
  error7: 'Please enter your surname.',
  error8: 'Please enter your phone number.',
  error9: 'Please enter confirm code.',
  emailNoRequired: 'Email is not mandatory!'
}