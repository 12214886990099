export default {
  heading: 'Профиль',
  settings: 'Настройки профиля',
  accounting: 'Бухгалтерская книга',
  ordersFromFlakonuz: 'Заказы от Flakon.uz',
  orders: 'Мои Заказы',
  order: 'Заказ',
  client: 'Клиент',
  saler: 'Продавец',
  process: 'Процесс',
  status: 'Статус',
  inProcess: 'В процессе',
  you: 'Вы',
  colorName: 'Название цвета',
  count: 'Количество',
  ready: 'Готово',
  checkingProducts: 'Процесс производства заказанных продуктов',
  startThisPartProcess: 'Приступите к выполнению этой части заказа',
  warehouse: 'Мой Склад',
  customer: 'Заказчик',
}