export default {
  search: 'Поиск',
  heading: 'Поиск по каталогу',
  selectPlaceholder1: 'Категория',
  selectPlaceholder2: 'Материал',
  priceInputPlaceholder1: 'Объем от',
  priceInputPlaceholder2: 'Объем до',
  searchBtn: 'Найти товары',
  dateTo: 'Дата до',
  dateFrom: 'Дата от',
}