export default {
  title: 'Оформление заказа',
  title2: 'Оформить заказ',
  title3: 'Ваш заказ успешно отправлен. Дождитесь одобрения продавца Flakon.uz',
  address: 'Адрес доставки',
  orderRecipient: 'Получатель заказа',
  productsOnOrder: 'Товары в заказе',
  orderRecipientInfoAbout1: 'Мы пришлем уведомление о статусе заказа на указанный вами телефон.',
  orderRecipientInfoAbout2: 'Курьер свяжется с вами по телефону для уточнения времени доставки.',
  confirmAndClose: 'Подтвердить и завершить количество произведённой продукции',
  confirmAndClose2: 'Подтвердить и завершить статус доставки',
  successSave: 'Успешно сохранено!',
  successConfirmed: 'Успешно Подтверждено!',
  manufactured: 'Изготовлено',
  deliveryProducts: 'Доставка продуктов',
  deliveringStatus: 'Статус доставки',
  deliveriesCheckingStatus: 'Статус проверки',
  notDelivering: 'Не доставляется',
  delivering: 'В процессе доставки',
  checkingOrderForAccept: 'Заказ подтверждается',
  collecting: 'Собирается',
  delivered: 'Доставлено',
  confirmed: 'Подтверждено',
  checkingProducts: 'Проверка и подтверждение продуктов',
  checkingStatus1: 'Ожидание доставки',
  checkingStatus2: 'Проверка',
  checkingStatus3: 'Подтвержден',
  checkingStatus4: 'Не подтверждено',
  contactWithClient: 'Связаться с клиентом по поводу неодобренных продуктов.',
  paymentStatus: 'Статус оплаты от Flakon.uz',
  paymentStatusForClient: 'Статус оплаты',
  paymentStatus1: 'Оплачено',
  paymentStatus2: 'Частично оплачено',
  paymentStatus3: 'Не оплачено',
  totalAmount: 'Общая сумма',
  paymentType: 'Способ оплаты',
  deliveryman: 'Курьер',
  carNumber: 'Номер машины',
  getted: 'Получено',
}