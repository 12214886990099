export default {
  heading: 'Saved products',
  heading2: 'Ordered products',
  back: 'Back',
  next: 'Next',
  button: 'Leave a request for saved products',
  button2: 'Deleting saved products',
  button3: 'Deleting ordered products',
  deletedProducts: ' product(s) you added to your cart have been removed from the site.',
  deletedOrderedProductsOne: ' product you ordered has been removed from the site.',
  deletedOrderedProductsMany: ' products you ordered have been removed from the site.',
  date: 'Date',
  kassa: 'Cash',
  izoh: 'Explanation',
  amount: 'Amount',
  totalAmount: 'Total amount',
  count: 'Count',
  totalCount: 'Total count',
  currency: 'Currency',
  kirim: 'Entry',
  chiqim: 'Output',
  aylanma: 'Turnover',
  boshqoldiq: 'First residue',
  oxirgiqoldiq: 'Last balance',
  product: 'Product',
  products: 'Products',
  character: 'Characteristics',
  yourOrder: 'Your order',
  goToCheckout: 'Go to ordering',
}