export default {
  search: 'Qidirish',
  heading: 'Katalog orqali qidirish',
  selectPlaceholde1: 'Turkum',
  selectPlaceholder2: 'Material',
  priceInputPlaceholder1: 'Hajm dan',
  priceInputPlaceholder2: 'Hajm gacha',
  searchBtn: 'Qidirish',
  dateTo: 'Sana gacha',
  dateFrom: 'Sana dan',
}