export default {
  heading: 'Saqlangan mahsulotlar',
  heading2: 'Buyurtma qilingan mahsulotlar',
  back: 'Ortga',
  next: 'Oldinga',
  button: "Saqlangan mahsulotlar bo'yicha ariza qoldirish",
  button2: "Saqlangan mahsulotlarni o'chirish",
  button3: "Buyurtma qilingan mahsulotlarni o'chirish",
  deletedProducts: " Savatingizga qo'shgan 2 ta mahsulot(lar)ingiz saytdan olib tashlandi.",
  deletedProductsUZB1: "Savatingizga qo'shgan ",
  deletedProductsUZB2: " ta mahsulot(lar)ingiz saytdan olib tashlandi.",
  deletedOrderedProductsUZB1: "Siz buyurtma bergan ",
  deletedOrderedProductsUZB2: " ta mahsulot(lar)ingiz saytdan olib tashlandi.",
  date: 'Sana',
  kassa: 'Kassa',
  izoh: 'Izoh',
  amount: 'Narx',
  totalAmount: 'Jami narxi',
  count: 'Soni',
  totalCount: 'Jami soni',
  currency: 'Valyuta',
  kirim: 'Kirim',
  chiqim: 'Chiqim',
  aylanma: 'Aylanma',
  boshqoldiq: 'Bosh qoldiq',
  oxirgiqoldiq: 'Oxirgi qoldiq',
  product: 'Mahsulot',
  products: 'Mahsulotlar',
  character: 'Xarakteristika',
  yourOrder: 'Buyurtmangiz',
  goToCheckout: "To'lovga o'tish",
}