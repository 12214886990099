export default {
  title: 'Catalog',
  heading: 'Packaging for cosmetics, household chemicals and perfumes',
  downloadBtn: 'Download the catalog',
  downloadBtnPdf: 'Download PDF',
  downloadBtnPdf2: 'Download Catalog PDF',
  priceListBtnPdf: 'Download Price List',
  shareVia: 'Share via:',
  copy: 'Copy link',
  accessories: 'Accessories',
}