import { createSlice } from '@reduxjs/toolkit'
import { localeSettings } from '../constants'
import Cookies from 'js-cookie'

// Types
import { ICategoryData, ICompanyNewsData, IPagination, IProduct, ISettings, IUser, IClients, IOrder, IProductInWarehouse } from 'types'

interface IStoreCache {
  productsInWarehouse: {
    productsInWarehouse: IProductInWarehouse[] | null
    pagination: IPagination
  }
  categories: {
    categories: ICategoryData[] | null
    pagination: IPagination
  }
  products: {
    products: IProduct[] | null
    pagination: IPagination
  }
  orders: {
    orders: IOrder[] | null
    pagination: IPagination
  }
  clients: IClients | []
  category: ICategoryData | null
  product: IProduct | null
  companyNews: {
    companyNews: ICompanyNewsData[] | null
    pagination: IPagination
  }
  currentNews: ICompanyNewsData | null
  navCategories: ICategoryData[] | null
}

interface IInitialState {
  isLoading: boolean
  cache: IStoreCache
  user: IUser
  settings: ISettings
}

const initialState: IInitialState = {
  isLoading: true,
  cache: {
    productsInWarehouse: {
      productsInWarehouse: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    categories: {
      categories: null,
      pagination: {
        page: 0,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    products: {
      products: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    orders: {
      orders: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    clients: [],
    category: null,
    product: null,
    companyNews: {
      companyNews: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    currentNews: null,
    navCategories: null,
  },
  user: {
    id: '',
    name: '',
    email: '',
    phone: '',
    phoneCode: 'UZ',
    surname: '',
    isAuth: false,
    userId: '',
    isFirst: true,
    orderedProducts: [],
    savedProducts: [],
    isEmailVerified: false,
    isPhoneVerified: false,
    isOrderingAllowed: false,
    isAccountingAllowed: false,
    isWarehouseAllowed: false,
    role: 'client',
    userToken: Cookies?.get('$W$E$B$F$L$A$K$O$N$.$U$Z$U$S$E$R$T$O$K$E$N$') || '',
  },
  settings: localeSettings,
}

const store = createSlice({
  name: 'store',
  initialState,
  reducers: {
    changeLoading: (state, action) => {
      state.isLoading = action?.payload
    },
    changeCurrentCurrency: (state, action) => {
      state.settings.currentCurrency = action?.payload
    },
    changeOrders: (state, action) => {
      state.cache.orders = action?.payload?.orders ? action?.payload : { pagination: action?.payload?.pagination, orders: action?.payload } || action?.payload
    },
    changeCategories: (state, action) => {
      switch (action?.payload?.isUpdateble) {
        case true:
          state.cache.categories = { pagination: action?.payload?.pagination, categories: [...(state.cache.categories.categories || []), ...action.payload.categories] }
          break
        case false:
          state.cache.categories = action?.payload
          break
      }
    },
    changeCategory: (state, action) => {
      state.cache.category = action?.payload
    },
    changeNavbarCategory: (state, action) => {
      state.cache.navCategories = action?.payload
    },
    changeProductsInWarehouse: (state, action) => {
      state.cache.productsInWarehouse = action?.payload?.productsInWarehouse ? action?.payload : { pagination: action?.payload?.pagination, productsInWarehouse: action?.payload } || action?.payload
    },
    changeProducts: (state, action) => {
      switch (!!action?.payload?.isUpdateble) {
        case true:
          state.cache.products = { pagination: action?.payload?.pagination, products: [...(state.cache.products.products || []), ...action.payload.products] }
          break
        case false:
          state.cache.products = action?.payload?.products ? action?.payload : { pagination: action?.payload?.pagination, products: action?.payload } || action?.payload
          break
      }
    },
    changeProduct: (state, action) => {
      state.cache.product = action?.payload
    },
    changeSettings: (state, action) => {
      state.settings = action?.payload
      
      state.cache.clients = action?.payload?.clients || []
      delete (state.settings as any).clients as any
      
      state.settings.currentCurrency = (localStorage?.getItem('$flakonuz$price$exchange$rate$currency$') as 'RUB' | 'USD' | 'UZS') || 'UZS'
    },
    changeCompanyNews: (state, action) => {
      state.cache.companyNews = { companyNews: action?.payload?.news, pagination: action?.payload?.pagination }
    },
    changeCurrentNews: (state, action) => {
      state.cache.currentNews = action?.payload
    },
    userAuth: (state, action) => {
      switch (action?.payload?.type) {
        case 'LOGIN':
          state.user = {
            isAuth: true,
            isFirst: !!action?.payload?.first,
            name: action?.payload?.data?.user?.name || action?.payload?.user?.name,
            phone: action?.payload?.data?.user?.phone || action?.payload?.user?.phone,
            phoneCode: action?.payload?.data?.user?.phoneCode || action?.payload?.user?.phoneCode || 'UZ',
            email: action?.payload?.data?.user?.email || action?.payload?.user?.email,
            surname: action?.payload?.data?.user?.surname || action?.payload?.user?.surname,
            id: action?.payload?.data?.user?.id || action?.payload?.user?.id,
            userId: action?.payload?.data?.user?.id || action?.payload?.user?.id,
            userToken: action?.payload?.data?.access_token || action?.payload?.access_token,
            isEmailVerified: action?.payload?.data?.user?.isEmailVerified || action?.payload?.user?.isEmailVerified || false,
            isPhoneVerified: action?.payload?.data?.user?.isPhoneVerified || action?.payload?.user?.isPhoneVerified || false,
            orderedProducts: action?.payload?.data?.user?.orderedProducts || action?.payload?.user?.orderedProducts,
            savedProducts: action?.payload?.data?.user?.savedProducts || action?.payload?.user?.savedProducts,
            nowRegistered: !!action?.payload?.nowRegistered,
            isOrderingAllowed: !false,
            isAccountingAllowed: !!action?.payload?.data?.user?.isAccountingAllowed || !!action?.payload?.user?.isAccountingAllowed,
            isWarehouseAllowed: !!action?.payload?.data?.user?.isWarehouseAllowed || !!action?.payload?.user?.isWarehouseAllowed,
            role: action?.payload?.data?.user?.role || action?.payload?.user?.role || 'client',
          }
          Cookies?.set('$W$E$B$F$L$A$K$O$N$.$U$Z$U$S$E$R$T$O$K$E$N$', action?.payload?.data?.access_token || action?.payload?.access_token, { expires: 10 })
          break

        case 'LOGOUT':
          state.user = {
            isAuth: false,
            isFirst: true,
            id: '',
            name: '',
            phone: '',
            phoneCode: 'UZ',
            email: '',
            surname: '',
            userId: '',
            userToken: '',
            orderedProducts: [],
            savedProducts: [],
            isEmailVerified: false,
            isPhoneVerified: false,
            nowRegistered: false,
            isOrderingAllowed: false,
            isAccountingAllowed: false,
            isWarehouseAllowed: false,
            role: 'client',
          }
          Cookies?.remove('$W$E$B$F$L$A$K$O$N$.$U$Z$U$S$E$R$T$O$K$E$N$')
          break
      }
    },
    changeUserProfile: (state, action) => {
      state.user = {
        ...state.user,
        name: action?.payload?.user?.name,
        surname: action?.payload?.user?.surname,
        verifyCode: action?.payload?.user?.verifyCode,
      }
    },
    changeOrderedProducts: (state, action) => {
      switch (action?.payload?.type) {
        case 'ADD':
          state.user = { ...state?.user, savedProducts: [], orderedProducts: action?.payload?.orderedProducts }
          break
        case 'REMOVE':
          state.user = { ...state?.user, orderedProducts: action?.payload?.orderedProducts }
          break
      }
    },
    changeSavedProducts: (state, action) => {
      state.user = { ...state?.user, savedProducts: action?.payload?.savedProducts }
    },
    changeAuth: (state, _action) => {
      state.user.isFirst = false
    },
    changeUserAccessToOrderingChackout: (state, action) => {
      state.user.isOrderingAllowed = action?.payload || false
    },
  },
})

export const {
  userAuth,
  changeAuth,
  changeOrders,
  changeLoading,
  changeProduct,
  changeCategory,
  changeSettings,
  changeProducts,
  changeCategories,
  changeUserProfile,
  changeCurrentNews,
  changeCompanyNews,
  changeSavedProducts,
  changeNavbarCategory,
  changeOrderedProducts,
  changeCurrentCurrency,
  changeProductsInWarehouse,
  changeUserAccessToOrderingChackout,
} = store?.actions
export default store.reducer