export default {
  error1: 'Пожалуйста, введите свой адрес э-почты.',
  error2: 'Пожалуйста, введите действительный адрес э-почты.',
  error3: 'Пожалуйста введите пароль.',
  error4: 'Пароль должен содержать как минимум 6 символов.',
  error5: 'Длина пароля должна быть не более 8 символов.',
  error6: 'Пожалуйста, введите Ваше имя.',
  error7: 'Пожалуйста, введите свою фамилию.',
  error8: 'Пожалуйста введите ваш номер телефона.',
  error9: 'Пожалуйста, введите код подтверждения.',
  emailNoRequired: 'Электронная почта не обязательна!'
}