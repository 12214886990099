export default {
  title: 'Katalog',
  heading: 'Kosmetika, maishiy kimyo va parfyumeriya uchun qadoqlash',
  downloadBtn: 'Katalogni yuklab oling',
  downloadBtnPdf: 'PDF yuklab olish',
  downloadBtnPdf2: "Katalog PDF ni yuklab olish",
  priceListBtnPdf: "Narxlar ro'yxatini yuklab olish",
  shareVia: 'Quyidagilar orqali ulashish:',
  copy: 'Havoladan nusxa olish',
  accessories: 'Aksessuarlar',
}