export default {
  title: 'Buyurtma',
  title2: 'Buyurtmani rasmiylashtirish',
  title3: 'Buyurtmangiz muvaffaqiyatli yuborildi. Flakon.uz sotuvchisining roziligini kuting',
  address: 'Yetkazib berish manzili',
  orderRecipient: 'Buyurtmani oluvchi',
  productsOnOrder: 'Buyurtmadagi mahsulotlar',
  orderRecipientInfoAbout1: "Siz ko'rsatgan telefon raqamga buyurtmangiz holati haqida bildirishnoma yuboramiz.",
  orderRecipientInfoAbout2: "Yetkazib berish muddatini aniqlashtirish uchun kurer siz bilan telefon orqali bog'lanadi.",
  confirmAndClose: 'Ishlab chiqarilgan mahsulotlar miqdorini tasdiqlash va tugatish',
  confirmAndClose2: 'Yetkazib berish holatini tasdiqlash va tugatish',
  successSave: 'Muvaffaqiyatli saqlandi!',
  successConfirmed: 'Muvofaqqiyatli tasdiqlandi!',
  manufactured: 'Ishlab chiqarildi',
  deliveryProducts: 'Mahsulotlarni yetkazib berish',
  deliveringStatus: 'Yetkazib berish statusi',
  deliveriesCheckingStatus: 'Tekshiruv statusi',
  notDelivering: 'Yetkazib berimayapti',
  delivering: 'Yetkazib berish jarayonida',
  checkingOrderForAccept: 'Buyurtma tasdiqlanmoqda',
  collecting: "Yig'ilmoqda",
  delivered: 'Yetkazib berildi',
  confirmed: 'Tasdiqlandi',
  checkingProducts: 'Mahsulotlarni tekshirish va tasdiqlash',
  checkingStatus1: 'Yetkazib berilishi kutilmoqda',
  checkingStatus2: 'Tekshirilmoqda',
  checkingStatus3: 'Tasdiqlandi',
  checkingStatus4: 'Tasdiqlanmadi',
  contactWithClient: "Tasdiqlanmagan mahsulotlar bo'yicha klient bilan bog'lanish",
  paymentStatus: 'Flakon.uz saytidan to‘lov holati',
  paymentStatusForClient: 'To‘lov holati',
  paymentStatus1: "To'landi",
  paymentStatus2: "Qisman to'landi",
  paymentStatus3: "To'lanmadi",
  totalAmount: 'Umumiy hisob',
  paymentType: "To'lov uslubi",
  deliveryman: 'Yetkazib beruvchi',
  carNumber: 'Mashina raqami',
  getted: 'Qabul qilindi',
}