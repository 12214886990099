export default {
  'accessories-for-bottles': 'Closures for bottles',
  'packaging-for-medicine': 'Medical packaging',
  'containers-for-food': 'Food bottles',
  'canisters': 'Canister',
  'jars-for-cosmetics': 'Cosmetic bottles',
  'packaging-for-household-chemicals': 'Household packaging',
  'pet-bottles': 'PET bottles',
  'petg-bottles': 'PET G bottles',
  'pe,-pp-bottles': 'PE, PP bottles',
}