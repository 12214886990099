export default {
  title: 'Placing an order',
  title2: 'Checkout the order',
  title3: 'Your order has been sent successfully. Wait for the approval of the Flakon.uz seller',
  address: 'Delivery address',
  orderRecipient: 'Recipient of the order',
  productsOnOrder: 'Products on order',
  orderRecipientInfoAbout1: 'We will send a notification about the status of your order to the phone number you provided.',
  orderRecipientInfoAbout2: 'The courier will contact you by phone to clarify the delivery time.',
  confirmAndClose: 'Confirm and finalize of the quantity of manufactured products',
  confirmAndClose2: 'Confirm and finalize of the delivery status',
  successSave: 'Saved successfully!',
  successConfirmed: 'Successfull confirmed!',
  manufactured: 'Manufactured',
  deliveryProducts: 'Delivery of products',
  deliveringStatus: 'Delivering status',
  deliveriesCheckingStatus: 'Checking status',
  notDelivering: 'Not delivering',
  delivering: 'In the process of delivery',
  checkingOrderForAccept: 'The order is being confirmed',
  collecting: 'Collecting',
  delivered: 'Delivered',
  confirmed: 'Confirmed',
  checkingProducts: 'Checking and confirming products',
  checkingStatus1: 'Waiting delivery',
  checkingStatus2: 'Checking',
  checkingStatus3: 'Confirmed',
  checkingStatus4: 'Not confirmed',
  contactWithClient: 'Contacting the client regarding unapproved products',
  paymentStatus: 'Payment Status from Flakon.uz',
  paymentStatusForClient: 'Payment Status',
  paymentStatus1: 'Paid',
  paymentStatus2: 'Partially paid',
  paymentStatus3: 'Unpaid',
  totalAmount: 'Total Amount',
  paymentType: 'Payment Method',
  deliveryman: 'DeliveryMan',
  carNumber: 'Car number',
  getted: 'Received',
}